import {isEmpty, isNullOrEmpty} from '../util/default-util'
import api from '../../api/api'

let mettlerToledoUsed = false
let allHardware = {}

export const isAgentInstalled = () => {
  let status = false
  const userData = localStorage.getItem('userData')
  const agentApiHost = JSON.parse(userData).agentApiHost
  if (!isNullOrEmpty(agentApiHost) && agentApiHost !== null) {
    status = true
  }
  return status
}
export const initialiseAllHardwareName = () => {
  if (isAgentInstalled()) {
    api.getShippingAgentCall('Diagnostics').then((response) => {
      allHardware = response.data
      console.log(response)
    }).catch((error) => {
      console.log(error)
    })
  }
}

export const isMettlerToledoUsed = () => {
  return mettlerToledoUsed
}

export const getActiveScales = () => {
  let name = ''
  if (allHardware && allHardware.activeScales) {
    name = allHardware.activeScales[0]
  }
  return name
}

export const showWeightScale = () => {
  if (getActiveScales() === 'Mettler Toledo USB Bench Scale') {
    mettlerToledoUsed = true
    return true
  } else {
    mettlerToledoUsed = false
    return false
  }
}

export const verifyIfMettlerToledoUsed = () => {
  mettlerToledoUsed = showWeightScale()
  
  return mettlerToledoUsed
}
