// ** Initial State
import {fullServeDefaultConfig} from '../../../constants/fullServe-constants'

const initialState = {
    nextBtnDisable: false
}

const {
    GetAddOnsNextBtnDisable
} = fullServeDefaultConfig.actions

const AddOnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetAddOnsNextBtnDisable:
            return {
                ...state,
                nextBtnDisable: action.nextBtnDisable
            }
        default:
            return state
    }
}

export default AddOnsReducer