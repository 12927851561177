import axios from 'axios'
import config from '../configs/configuration'
import {getApiObject, setApiObject} from '../global-constants'
import {isEmpty} from '../commons/util/default-util'
import {useEffect, useMemo, useState} from 'react'
import CustomSettingsService from '../commons/services/customSettingsService'

function configSetter() {
    console.log('origin', window.location.origin)

    switch (window.location.origin) {
        // UAT
        case 'https://splus-uat-falconse.pnimedia-uat.com':
            return 'https://splus-uat-falcon-api.pnimedia-uat.com'
        // PROD with PNI Domain
        case 'https://splus-falconse.pnimedia.com':
            return 'https://splus-falcon-api.pnimedia.com'
        // PROD with Staples Domain
        case 'https://falconse.staples.com':
            return 'https://splus-falcon-api.pnimedia.com'

        // Local / QA / Default point to QA
        case 'http://localhost:8899':
        case 'https://splus-qa-shipping-ui.pnidev.com':
        default:
           return 'https://splus-qa-falcon-api.pnidev.com'
         // return 'https://splus-uat-falcon-api.pnimedia-uat.com'
    }
}

function configHRUrlSetter() {
    console.log(window.location.origin)

    switch (window.location.origin) {
        // UAT
        case 'https://splus-uat-falconse.pnimedia-uat.com':
            return 'https://staples-dev.happyreturns.com'
        // PROD
        case 'https://falconse.staples.com':
            return 'https://staples.happyreturns.com'
        // Local / QA / Default point to Prod
        case 'http://localhost:3000':
            return 'https://staples-dev.happyreturns.com'
        case 'https://splus-qa-falconse.pnidev.com':
            return 'https://staples-dev.happyreturns.com'
        default:
            return 'https://staples.happyreturns.com'
    }
}

// Remove it on logout
const googleMapsApiCall = (googleApiKey) => {
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
    document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
}

export const fetchApiSettings = () => {
    // This is for SFC to get environment settings key, apiKey and google api key
    console.log('fetchApiSettingsCall')
    fetch(`app.settings.json`)
        .then((response) => response.json())
        .then((findResponse) => {
            // Other ENV variables will be returned here for using in whole app for SFC
            if (findResponse && findResponse.Settings.Urls) {
                const urls = findResponse.Settings.Urls
                console.log(urls)
                console.log(`fetchApiSettingsResponse -> Urls.EnvApi: ${urls.EnvApi}`)
                // Change this based on environment
                config['baseUrl_App'] = (urls.EnvApi === '' || urls.EnvApi.includes('$(ShippingApiUrl)')) ? configSetter() : urls.EnvApi
                config['googleApiKey'] = (urls.EnvGoogleApiKey === '' || urls.EnvGoogleApiKey.includes('$(ShippingGoogleApiKey)')) ? 'AIzaSyD4-KPr3tgBuJ36a4iAF-9Cl2zJU6Hn1vU' : urls.EnvGoogleApiKey
                console.log(`config[happyReturnsUrl] ${urls.HappyReturnsApiUrl}`)
                config['happyReturnsUrl'] = urls.HappyReturnsApiUrl === '' || urls.HappyReturnsApiUrl.includes('$(HappyReturnsApiUrl)') ? configHRUrlSetter() : urls.HappyReturnsApiUrl
                console.log(`config[happyReturnsUrl] ${config['happyReturnsUrl']}`)
                // config['happyReturnsUrl'] = (urls.HappyReturnsApiUrl === '' || urls.HappyReturnsApiUrl.includes('$(HappyReturnsApiUrl)')) ? 'https://staples.happyreturns.com/' : urls.HappyReturnsApiUrl
 
                // const googleApiKey = findResponse.Settings.Urls.EnvGoogleApiKey
                const googleApiKey = config['googleApiKey']
                googleMapsApiCall(googleApiKey)
                setApiObject(findResponse)
                localStorage.setItem('envVarName', JSON.stringify(config['baseUrl_App']))
            }
        })
}
// Initial Call Made to set all the keys from app settings
fetchApiSettings()

export const getBaseUrl = async () => {
    return config['baseUrl_App']
}

export const getShippingAgentApi = () => {
    if (!isEmpty(JSON.parse(localStorage.getItem('userData')))) {
        return JSON.parse(localStorage.getItem('userData')).agentApiHost
    }
    return 'https://localhost:63842'
}

export const instance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
})

export const instanceShippingAgent = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
})

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0)
    
    const interceptors = useMemo(() => {
        const inc = () => setCounter(counter => counter + 1)
        const dec = () => setCounter(counter => counter - 1)
        
        return ({
            request: config => (inc(), config),
            response: response => (dec(), response),
            error: error => (dec(), Promise.reject(error))
        })
    }, []) // create the interceptors
    
    useEffect(() => {
        // add request interceptors
        const reqInterceptor = instance.interceptors.request.use(interceptors.request, interceptors.error)
        // add response interceptors
        const resInterceptor = instance.interceptors.response.use(interceptors.response, interceptors.error)
        return () => {
            // remove all intercepts when done
            instance.interceptors.request.eject(reqInterceptor)
            instance.interceptors.response.eject(resInterceptor)
        }
    }, [interceptors])
    return [counter > 0]
}

export default {
    getAllData: (api) => instance({
        method:'GET',
        url: api
    }),
    updateObjectCall: (api, data) => instance({
        method:'PUT',
        url: api,
        data
    }),
    createObjectCall: (api, data) => instance({
        method: 'POST',
        url: api,
        data
    }),
    deleteObjectCall: (api) => instance({
        method:'DELETE',
        url: api,
        data
    }),
    getShippingAgentCall: (api) => instanceShippingAgent({
        method: 'GET',
        url: `/api/${api}`
    }),
    printShipmentLabel: (api, data) => instanceShippingAgent({
        method: 'POST',
        url: `/api/${api}`,
        data
    }),
    downloadAsset: (api) => instance({
        method: 'GET',
        url: `/api/${api}`,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf'
        }
    }),
    createObjectCallWithId: (api, id) => instance({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        url: `${api}/${id}`
    })
    
}
