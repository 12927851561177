// ** Initial State
import {fullServeDefaultConfig} from '../../../constants/fullServe-constants'

const initialState = {
    data: [],
    pagedData: {},
    error: null,
    showDropDown: false,
    errorFlag: false,
    validatedAddress: {},
    showAddress: false,
    nextBtnDisable: false
}

const {
    GetCustomersSearchData,
    CustomerSuccessSearchData,
    GetAddressValidationResult,
    GetAddressFilledCorrect,
    GetNextBtnDisable
} = fullServeDefaultConfig.actions

const ShipFromReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetCustomersSearchData:
            return {
                ...state,
                data: action.data,
                pagedData: action.pagedData,
                error: null
            }
        case CustomerSuccessSearchData:
            return {
                ...state,
                showDropDown: action.showDropDown,
                errorFlag: action.errorFlag
            }
        case GetAddressValidationResult:
            return {
                ...state,
                validatedAddress: action.data
            }
        case GetAddressFilledCorrect:
            return {
                ...state,
                showAddress: action.showAddress
            }
        case GetNextBtnDisable:
            return {
                ...state,
                nextBtnDisable: action.nextBtnDisable
            }
        default:
            return state
    }
}

export default ShipFromReducer