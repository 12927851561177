export const isNullOrEmpty = (value) => {
  return !((value) && value.length > 0)
}

export const convertIntoDecimal = (num) => {
  let result
  if (num) {
    result = parseFloat(num)
  }
  return result
}

export const getDecimalValue = (num) => {
  let result
  if (num) {
    num = convertIntoDecimal(num)
    result = num.toFixed(2)
  }
  return result
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

export const removeWhiteSpace = (string) => {
  return string.replace(/\s+/g, '')
}