// ** Initial State
import {fullServeDefaultConfig} from '../../../constants/fullServe-constants'

const initialState = {
    data: [],
    pagedData: {},
    error: null,
    showDropDown: false,
    errorFlag: false,
    validatedAddress: {},
    showAddress: false,
    nextBtnDisable: false
}

const {
    GetRecipientSearchData,
    RecipientSuccessSearchData,
    GetShipToAddressResult,
    GetShipToAddressCorrect,
    GetShipToNextBtnDisable
} = fullServeDefaultConfig.actions

const ShipToReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetRecipientSearchData:
            return {
                ...state,
                data: action.data,
                pagedData: action.pagedData,
                error: null
            }
        case RecipientSuccessSearchData:
            return {
                ...state,
                showDropDown: action.showDropDown,
                errorFlag: action.errorFlag
            }
        case GetShipToAddressResult:
            return {
                ...state,
                validatedAddress: action.data
            }
        case GetShipToAddressCorrect:
            return {
                ...state,
                showAddress: action.showAddress
            }
        case GetShipToNextBtnDisable:
            return {
                ...state,
                nextBtnDisable: action.nextBtnDisable
            }
        default:
            return state
    }
}

export default ShipToReducer