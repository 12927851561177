export const historyConstants = {
    shipmentListApi: 'api/Shipment/View',
    shipmentDetail: 'api/Shipment/shipmentDetails',
    shipmentStatus: 'api/JsTypes/Pni.Shipping.Interfaces.Enums.RateAndShip.ShipmentStatus',
    shipmentSourcE: 'api/JsTypes/Pni.Shipping.Interfaces.Enums.RateAndShip.ShipmentSource',
    dropOffApi: 'api/DropOffs',
    returnApi:'api/Returns',
    returnDetailApi: 'api/Returns/Batch',
    upsTracking: '/api/Settings/UPS.TrackingUrl/en-US',
    fedexTracking: 'api/Settings/Fedex.TrackingUrl/en-US',
    uspsTracking: 'api/Settings/USPS.TrackingUrl/en-US',
    facilitySetting: '/api/Facility',
    facilityLookup: 'api/Facilities/Lookup',
    actions : {
        GetShipmentsListData : 'GET_SHIPMENTS_LIST_DATA',
        GetShipmentsDetail: 'GET_SHIPMENT_DETAIL_DATA',
        GetDropOffListData: 'GET_DROPOFF_LIST_DATA',
        GetReturnListData: 'GET_RETURN_LIST_DATA',
        GetReturnDetail: 'GET_RETURN_DETAIL_DATA'
    }
    
}
