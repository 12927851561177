export const facilitiesDefaultConfig = {
    facilitiesApi : `api/Facilities`,
    countriesApi : `api/Countries`,
    actions : {
        GetFacilitiesData : 'GET_FACILITIES_DATA',
        AddFacilitiesData : 'ADD_FACILITIES_DATA',
        DeleteFacilitiesData : 'DELETE_FACILITIES_DATA',
        UpdateFacilitiesData : 'UPDATE_FACILITIES_DATA',
        ErrorFacilitiesData : 'ERROR_FACILITIES_DATA',
        SuccessFacilitiesData : 'SUCCESS_FACILITIES_DATA'
    }
}