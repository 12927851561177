import {isEmpty, isNullOrEmpty} from './default-util'
import {CardBody} from 'reactstrap'
import React from 'react'
import {find} from 'lodash'
import {isMettlerToledoUsed} from '../services/shippingAgentService'
import { Home } from 'react-feather'
import { AiOutlineApartment } from 'react-icons/ai'

export const currentFacilitySetter = () => {
  const facility = {}
  if (!isEmpty(JSON.parse(localStorage.getItem('userData')))) {
    facility.facilityId = JSON.parse(localStorage.getItem('userData')).currentFacilityId
    facility.facilityName = JSON.parse(localStorage.getItem('userData')).currentFacilityName
  }
  return facility
}

export const perPackageFeeCalc = (packageFee) => {
  return (packageFee.totalFee - (
    packageFee.declaredValueFee +
    packageFee.notificationFee +
    packageFee.proofFee +
    packageFee.environmentalFee +
    packageFee.saturdayDeliveryFee +
    packageFee.additionalHandlingFee +
    packageFee.oversizeFee)).toFixed(2)
}

// Update for multipackages
export const totalFeesCalculation = (carrierRate, shipmentModelPackedBy, lengthPackages) => {
  let totalFee = 0
  if (carrierRate !== null) {
    _.forEach(carrierRate.packageFees, function (pack) {
      if (lengthPackages === 1) {
        totalFee = pack.totalFee + pack.packingFee + pack.boxFee +
          (carrierRate.returnSurcharge !== undefined ? carrierRate.returnSurcharge : carrierRate.returnSurhcarge)
        
      } else {
        // Multiple Package
        totalFee += pack.totalFee + pack.packingFee + pack.boxFee
      }
    })
  }
  return (totalFee).toFixed(2)
}

export const carbonNeutralFeesCalculation = (carrierRate) => {
  let totalFee = 0
  if (carrierRate !== null) {
    _.forEach(carrierRate.packageFees, function (pack) {
      totalFee += pack.environmentalFee
    })
  }
  return (totalFee).toFixed(2)
}

export const residentialTranslate = (isRes) => {
  if (isRes) {
    return 'Residential'
  } else {
    return 'Commercial'
  }
}

export const phoneNumberTranslate = (countryCode, num) => {
  if (num === null || num === '') {
    return ''
  }
  if (countryCode === '' || countryCode === null) {
    return (`${num.substring(0, 3)}-${num.substring(3, 6)}-${num.substring(6)}`)
  } else {
    if (countryCode.substring(0, 1) === '+') {
      return (`${countryCode} ${num.substring(0, 3)}-${num.substring(3, 6)}-${num.substring(6)}`)
    } else {
      return (`+ ${countryCode} ${num.substring(0, 3)}-${num.substring(3, 6)}-${num.substring(6)}`)
    }
  }
}

export const renderAddress = (name) => {
  return (
    <CardBody>
      <p>{name.contactName}</p>
      <p>{name.companyName}</p>
      <p>{name.street1}</p>
      <p>{name.street2}</p>
      <p>{name.city}, {name.state}, {name.country}, {name.postalCode}</p>
      <p>{name.isResidential ? <Home size={'16px'} /> : <AiOutlineApartment size={'16px'} />} {residentialTranslate(name.isResidential)}</p>
      <p>{phoneNumberTranslate(name.countryCode, name.phoneNumber)} {name.phoneExt}</p>
      <p>{name.email && <span><strong>Send status updates to:</strong></span>}</p>
      <p>{name.email}</p>
    </CardBody>
  )
}

export const defaultPackage = {
  dimension: {
    length: '',
    width: '',
    height: ''
  },
  weight: '',
  description: '',
  packedBy: '',
  packageNumber: 1,
  referenceNumber1: '',
  referenceNumber2: '',
  isPackageValidated: true,
  isServiceValidated: true,
  packageServiceOptions: {
    declaredValue: {
      value: ''
    },
    notification: {
      notificationEventMethod: 1,
      notificationEmailBody: '',
      notificationEmailTo1: '',
      notificationEmailTo2: '',
      notificationEmailTo3: '',
      notificationEmailTo4: '',
      notificationEmailTo5: '',
      notificationEventOnDlv: '',
      notificationEventOnExc: '',
      notificationEventOnShp: '',
      notificationPhone: ''
    },
    proof: {
      proofRequireSignature: false,
      proofRequireAdultSignature: false,
      proofLabel: ''
    },
    declaredValueServiceSelected: false,
    notificationServiceSelected: false,
    proofServiceSelected: false,
    recipientEmailCount: 0
  },
  uiModel: {
    boxDescription: 'Custom',
    preDefinedPackageCode: 'Custom',
    carrierCode: 'Generic',
    carrierName: 'Generic',
    showPreDefinedDimensionFields: true
  },
  preDefinedPackageCode: 'Custom',
  mainWeight: '',
  subWeight: '',
  isWeightEnteredManually: true,
  packageType: 1,
  contentType: '',
  isDisableNextHazmatError: false,
  isPackageHazmatRestricted: false,
  isPackDescriptionNotConfirmed: true,
  shipmentContainsDocuments: false,
  shipmentContainsGoods: false,
  shipmentContainsMonetary: false,
  courierTrackingNumber: '',
  packagingPrice: 0,
  boxPrice: 0,
  hazmatTriggerWords: [],
  numberOfPackages: 1
}

export const customPackage = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'Custom',
  code: 'Custom',
  description: 'Custom',
  length: '',
  width: '',
  height: '',
  dimensionUnit: 1,
  carrierId: '',
  facilityIds: null,
  carrierServiceLevelIds: null,
  packageType: '',
  displayName: 'Custom',
  carrierName: ''
}

export const checkCarriers = (carriers, carrierId) => {
  return find(carriers, {id: carrierId})
}

export const getWeightInPounds = (mainWeight, subWeight) => {
  if (isNullOrEmpty(mainWeight)) {
    mainWeight = 0
  }
  
  if (isNullOrEmpty(subWeight)) {
    subWeight = 0.0
  }
  let weight = parseFloat(mainWeight)
  weight = weight + (parseFloat(subWeight) * 0.0625)
  return weight
}

export const formatWeight = (weight) => {
  return weight.toFixed(2)
}

function pToLb(pO) {
  const lb = Math.floor(pO)
  const n = (pO % 1)
  let oz = parseFloat((n * 16).toFixed(1))
  
  if (oz >= 0) {
    oz = oz.toFixed(1)
  }
  
  return {
    pounds: lb,
    ounces: oz
  }
}

export const getWeightConversion = (weight) => {
  if (isMettlerToledoUsed()) {
    return pToLb(weight)
  }
}

export const quantityFieldValidation = (value) => {
  const errors = {}
  if (value === '') {
    errors.quantity = 'This is the required field'
  }
  return errors
}
