// ** Initial State
import {pricingDefaultConfig} from "../../../constants/pricing-constants"

const initialState = {
    data: [],
    params: {},
    error: null,
    success: false
}

const {
    GetPricingData,
    ErrorPricingData,
    SuccessPricingData,
    DeletePricingData,
    UpdatePricingData,
    AddPricingData
} = pricingDefaultConfig.actions

const DataTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetPricingData:
            return {
                ...state,
                data: action.data,
                params: action.params,
                error: null
            }
        case AddPricingData:
            return { ...state, error: null }
        case UpdatePricingData:
            return { ...state, error: null }
        case DeletePricingData:
            return { ...state }
        case ErrorPricingData:
            return {
                ...state,
                error: action.error
            }
        case SuccessPricingData:
            return {
                ...state,
                success: action.success
            }
        default:
            return state
    }
}

export default DataTableReducer