// ** Initial State
import {fullServeDefaultConfig} from '../../../constants/fullServe-constants'

const initialState = {
    nextBtnDisable: false,
    weightObj: {}
}

const {
    GetPackageNextBtnDisable,
    PackageWeightScale
} = fullServeDefaultConfig.actions

const PackageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetPackageNextBtnDisable:
            return {
                ...state,
                nextBtnDisable: action.nextBtnDisable
            }
        case PackageWeightScale:
            return {
                ...state,
                weightObj: action.data
            }
        default:
            return state
    }
}

export default PackageReducer