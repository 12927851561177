// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import pricing from './admin/pricing'
import systemSetting from './admin/settings'
import facilities from './admin/facilities'
import shipFrom from './shipping/shipFrom'
import shipTo from './shipping/shipTo'
import ratesStore from './shipping/rates'
import manifest from './manifest'
import historyStore from './history'
import packageStore from './shipping/package'
import addOnsStore from './shipping/addOns'
import quoteStore from './quote'
import ordersJobsStore from './orders-jobs'
import hubsStore from './hubs-flows'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  pricing,
  systemSetting,
  facilities,
  shipFrom,
  shipTo,
  ratesStore,
  manifest,
  historyStore,
  packageStore,
  addOnsStore,
  quoteStore,
  ordersJobsStore,
  hubsStore
})

export default rootReducer
