// ** Initial State
import {fullServeDefaultConfig} from '../../../constants/fullServe-constants'
import {
  defaultShipmentServiceOptions,
  initialShipmentModel
} from '../../../../views/shipping/util/fullServe-util'

const initialState = {
  payloadData: {},
  initialShipmentModel,
  stepMove: false,
  ratesData: {},
  stepAddOns: false,
  selRatesData: {},
  stepRates: false,
  stepSuccess: false,
  errorSuccess: false
}

const {
  CustomerSavePayload,
  RecipientSavePayload,
  PackageSavePayload,
  RatesPayload,
  RatesResponse,
  ResetPayload,
  RatesSelected,
  UpgradeService,
  ReviewRates,
  AddOnsRates,
  FullServePayload,
  SelectedRatesResponse,
  ShipmentModel,
  SuccessScreenData,
  ReviewPrevRates,
  ResetFullServeData,
  PrevAddOnsRates,
  FailureFullServeData
} = fullServeDefaultConfig.actions

const RatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerSavePayload:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            // shipper: action.payloadData,
            shipper: {
              ...action.payloadData.shipFromData,
              isResidential: action.payloadData.isResidential,
              postalCode: action.payloadData.postalCode
            }
          }
        }
      }
    case RecipientSavePayload:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            // recipient: action.payloadData,
            recipient: {
              ...action.payloadData.shipToData,
              isResidential: action.payloadData.isResidential,
              postalCode: action.payloadData.postalCode
            }
          }
        }
      }
    case PackageSavePayload:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            isWeightUnitSwitched: action.payloadData.showWeightUnitSwitched,
            packages: action.payloadData.multiPackagesUpdated
          }
        }
      }
    case ResetPayload:
      return {
        ...state,
        ratesData: {},
        selRatesData: {},
        initialShipmentModel: {
          ...state.initialShipmentModel,
          serviceCode: '',
          serviceSelected: false,
          saturdayRateSelected: false,
          rateRequestId: '',
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            shipDate: '',
            saturdayRateSelected: false,
            requestedServiceTypes: [
              {
                carrier: 'UPS',
                serviceLevel: ''
              }
            ],
            packages: action.packages,
            shipmentServiceOptions: defaultShipmentServiceOptions
          }
        },
        stepMove: action.stepMove
      }
    case RatesPayload:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: state.initialShipmentModel.rateModel
        },
        stepMove: action.stepMove
      }
    case RatesResponse:
      return {
        ...state,
        ratesData: action.data
      }
    case RatesSelected:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          serviceCode: action.payloadData.serviceCode,
          serviceSelected: action.payloadData.serviceSelected,
          saturdayRateSelected: action.payloadData.saturdayDelivery,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            saturdayRateSelected: action.payloadData.saturdayDelivery,
            requestedServiceTypes: [
              {
                carrier: action.payloadData.carrier,
                serviceLevel: action.payloadData.serviceLevel
              }
            ]
          }
        }
      }
    case UpgradeService:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            originalCarrierService: action.payloadData.carrierService,
            upsellPriceChange: action.payloadData.value
          }
        }
      }
    case SelectedRatesResponse:
      return {
        ...state,
        selRatesData: action.data
      }
    // Called on Rates Next
    case AddOnsRates:
      return {
        ...state,
        stepAddOns: action.stepAddOns,
        payloadData: {},
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateRequestId: action.payloadData.rateRequestId,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            shipDate: action.payloadData.shipDate
          }
        }
      }
    // Called on Rates Next
    case PrevAddOnsRates:
      return {
        ...state,
        stepAddOns: action.stepAddOns
      }
    // Called on Addons Next
    case ReviewRates:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            shipmentServiceOptions: action.rateStorePayload.rateModel.shipmentServiceOptions,
            deliveryDateTime: state.selRatesData && state.selRatesData.length > 0 && state.selRatesData[0].rates[0].deliveryDate,
            packages: action.rateStorePayload.rateModel.packages
          }
        },
        stepRates: action.stepRates,
        selRatesData: {}
      }
    case ReviewPrevRates:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            deliveryDateTime: state.selRatesData && state.selRatesData.length > 0 && state.selRatesData[0].rates[0].deliveryDate
          }
        },
        stepRates: action.stepRates
      }
    // Called on Rates Api Call
    case FullServePayload:
      return {
        ...state,
        ...initialShipmentModel
      }
    // Called on Review Confirm Screen and add whatever needs to go for Ship model here
    case ShipmentModel:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          shipModel: state.initialShipmentModel.rateModel
        }
      }
    case SuccessScreenData:
      return {
        ...state,
        payloadData: action.data,
        stepSuccess: action.stepSuccess
      }
    case ResetFullServeData:
      return initialState
    case FailureFullServeData:
      return {
        ...state,
        errorSuccess: true
      }
    default:
      return state
  }
}

export default RatesReducer