import {initialShipmentModel} from '../../../views/hubs-shared-components/hubs-shared-util'
import {hubsConstants} from '../../constants/hubs-constants'
import {defaultPackage} from '../../../commons/util/common-places-used-util'

const initialState = {
  initialShipmentModel,
  data: [],
  validatedAddress: {},
  addressValidationResponseSuccess: false,
  ratesTableData: {},
  displayRatesTable: false,
  callRatesOneClick: false
}

const HubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case hubsConstants.actions.GetOrdersJobsShippingDetails:
      return {
        ...state,
        data: action.data
      }
    case hubsConstants.actions.GetShipToAddressResult:
      return {
        ...state,
        validatedAddress: action.data,
        addressValidationResponseSuccess: action.addressValidationResponseSuccess
      }
    case hubsConstants.actions.GetDCSRatesCallForHubs:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            jobDetails: action.data,
            packages: [{...defaultPackage, weight: 1}],
            shipper: {
              ...state.data[0].shipFrom
            },
            recipient: {
              ...state.data[0].shipTo,
              countryCode: `+${state.data[0].shipTo.countryCode}`,
              isResidential: state.validatedAddress.modifiedAddress.isResidential
            },
            requestedServiceTypes: [
              {
                carrier: 'UPS',
                serviceLevel: ''
              }
            ]
          }
        },
        callRatesOneClick: action.callRatesOneClick
      }
    case hubsConstants.actions.GetRatesTableResult:
      return {
        ...state,
        ratesTableData: action.data
      }
    case hubsConstants.actions.GetRatesTableDisplay:
      return {
        ...state,
        displayRatesTable: action.data
      }
    case hubsConstants.actions.GetShipmentSuccess:
      return {
        ...state,
        shipmentSuccessResponse: action.data,
        shipmentSuccess: true
      }
    case hubsConstants.actions.GetSelectedRate:
      return {
        ...state,
        initialShipmentModel: {
          ...state.initialShipmentModel,
          serviceCode: action.data.serviceCode,
          serviceSelected: true,
          saturdayRateSelected: action.data.saturdayDelivery,
          rateRequestId: state.ratesTableData[0].id,
          rateModel: {
            ...state.initialShipmentModel.rateModel,
            shipDate: state.ratesTableData[0].shipDate,
            deliveryDateTime: action.data.deliveryDate,
            saturdayRateSelected: action.data.saturdayDelivery,
            serviceCode: action.data.serviceCode,
            serviceSelected: true,
            rateRequestId: state.ratesTableData[0].id,
            requestedServiceTypes: [
              {
                carrier: action.data.carrierName,
                serviceLevel: action.data.serviceCode
              }
            ]
          }
        }
      }
    default:
      return state
  }
}

export default HubsReducer