export const ManifestConstants = {
    loadManifest : 'api/Manifests',
    performManifestShipments : 'api/Manifests/Shipment',
    performManifestDropOff : 'api/Manifests/Dropoff',
    closedManifest: 'api/Manifest',
    shipmentStatus: 'api/JsTypes/Pni.Shipping.Interfaces.Enums.RateAndShip.ShipmentStatus',
    actions : {
        GetManifestData : 'GET_DATA_MANIFEST',
        GetManifestHistoryShipmentsData: 'GET_DATA_MANIFEST_SHIPMENTS'
    }
}