export const settingsDefaultConfig = {
    settingsApi : `api/SettingsAdmin`,
    actions : {
        GetSettingsData : 'GET_SETTINGS_DATA',
        AddSettingsData : 'ADD_SETTINGS_DATA',
        DeleteSettingsData : 'DELETE_SETTINGS_DATA',
        UpdateSettingsData : 'UPDATE_SETTINGS_DATA',
        ErrorSettingsData : 'ERROR_SETTINGS_DATA',
        SuccessSettingsData : 'SUCCESS_SETTINGS_DATA'
    }
}