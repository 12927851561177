import {ManifestConstants} from '../../constants/manifest-constants'

const initialState = {
    data: []
}

const {GetManifestData, GetManifestHistoryShipmentsData} = ManifestConstants.actions

const ManifestReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetManifestData:
            return {
                ...state,
                data: action.data
            }
        case GetManifestHistoryShipmentsData:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default ManifestReducer