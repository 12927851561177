import {uniqBy} from 'lodash'

export const initialShipmentModel = {
  serviceSelected: false, //make true when going rates to confirm and pass service code
  serviceCode: '',
  isNonNationalCarrierShipment: false,
  rateModel: {
    isNonNationalCarrierShipment: false,
    requestedServiceTypes: [],
    shipDate: '',
    shipmentServiceOptions: {},
    packages: [],
    shipper: {},
    recipient: {},
    commercialInvoice: {},
    jobDetails: [],
    shipmentId: null, //gets created on pause
    deliveryDateTime: '',
    pausedStep: 0,
    saturdayRateSelected: false,
    isWeightUnitSwitched: false,
    documentsOnly: false,
    associateInitials: '',
    isDcsShipment: true,
    source: 3 // change according to hubs flows
  },
  rateRequestId: null, // pass rate request ID when going rates to confirm which u get in response of get rates
  saturdayRateSelected: false,
  useThirdPartyAccount: false,
  advanceShippingDate: false
}

export const initialRecipientPayload = {
  contactName: '',
  companyName: '',
  street1: '',
  street2: '',
  city: '',
  postalCode: '',
  countryCode: '+1',
  country: 'US',
  state: '',
  phoneNumber: '',
  phoneExt: '',
  phoneType: '',
  email: '',
  // required field in back-end
  isResidential: null
}

export const initialShipMethod = {
  associateInitials: '',
  courierTrackingNumber: '',
  shipmentTotal: '',
  referenceNumber1: '',
  referenceNumber2: '',
  courierName: '',
  service: ''
}

export const isSimilarHashKeys = (hashKeysObjects, setStartShippingError, setStartShippingErrorMessage) => {
  if (hashKeysObjects.length === 0) {
    // autoscroll to top
    setStartShippingError(true)
    setStartShippingErrorMessage('Please select at least one job.')
    return false
  }
  if (hashKeysObjects.length >= 51) {
    // autoscroll to top
    setStartShippingError(true)
    setStartShippingErrorMessage('You cannot select more than 50 jobs at once.')
    return false
  }
  if (uniqBy(hashKeysObjects, 'hashKey').length > 1) {
    // autoscroll to top
    setStartShippingError(true)
    setStartShippingErrorMessage('Please select jobs that have a matching address and recipient.')
    return false
  }
  setStartShippingError(false)
  return true
}

export const filterValueBillToThirdParty = [
  {
    name: 'Company Name',
    backendName: 'CompanyName',
    id: 1
  },
  {
    name: 'Contact Name/Attn',
    backendName: 'ContactName',
    id: 2
  },
  {
    name: 'Account Number',
    backendName: 'AccountNumber',
    id: 3
  }
]

function filterRatesCarrier(ratesData, carrierName) {
  return ratesData.filter(rate => rate.carrierName === carrierName).length
}

export const hubsCarrierTabs = (ratesData) => {
  return [
    {
      title: 'All Available Carriers',
      batchDetail: ratesData.length,
      activeId: 0
    },
    {
      title: 'UPS',
      batchDetail: filterRatesCarrier(ratesData, 'UPS'),
      activeId: 1
    },
    {
      title: 'Fedex',
      batchDetail: filterRatesCarrier(ratesData, 'Fedex'),
      activeId: 2
    },
    {
      title: 'USPS',
      batchDetail: filterRatesCarrier(ratesData, 'USPS'),
      activeId: 3
    }
  ]
}

export const resetRatesPayload = (hubsRatesData, setHubsRatesData, shipWithRegionalCourier) => {
  // remove the set rates for one quick flow
  setHubsRatesData({
    ...hubsRatesData,
    serviceCode: '',
    serviceSelected: false,
    saturdayRateSelected: false,
    isNonNationalCarrierShipment: shipWithRegionalCourier,
    shipmentTotal: '',
    rateModel: {
      ...hubsRatesData.rateModel,
      isNonNationalCarrierShipment: shipWithRegionalCourier,
      deliveryDateTime: '',
      saturdayRateSelected: false,
      serviceCode: '',
      serviceSelected: false,
      shipmentTotal: '',
      requestedServiceTypes: [
        {
          carrier: 'UPS',
          serviceLevel: ''
        }
      ]
    }
  })
}