export const searchFiltersMinCharacters = (filterType, searchLength) => {
    if (filterType.toLowerCase() === 'phonenumber') {
        return searchLength < 6 || searchLength > 20
    }
    if (filterType.toLowerCase() === 'rewardsnumber') {
        return searchLength < 6 || searchLength > 20
    } else {
        return searchLength < 2 || searchLength > 35
    }
}

export const phoneNumberRegexCheck = (value) => {
    return /^[0-9 \-]+$/.test(value)
}

export const defaultPackageServiceOptions = {
    declaredValue: {
        value: ''
    },
    notification: {
        notificationEventMethod: 1,
        notificationEmailBody: '',
        notificationEmailTo1: '',
        notificationEmailTo2: '',
        notificationEmailTo3: '',
        notificationEmailTo4: '',
        notificationEmailTo5: '',
        notificationEventOnDlv: '',
        notificationEventOnExc: '',
        notificationEventOnShp: '',
        notificationPhone: ''
    },
    proof: {
        proofRequireSignature: false,
        proofRequireAdultSignature: false,
        proofLabel: ''
    },
    declaredValueServiceSelected: false,
    notificationServiceSelected: false,
    proofServiceSelected: false,
    recipientEmailCount: 0
}

export const defaultShipmentServiceOptions = {
    carbonNeutral: {
        value: ''
    },
    carbonNeutralSelected: false,
    returnService: {
        returnDeliveryMethod: "5",
        returnAddressAddress1: '',
        returnAddressAddress2: '',
        returnAddressCity: '',
        returnAddressCompany: '',
        returnAddressContact: '',
        returnAddressPhone: '',
        returnAddressPostalCode: '',
        returnAddressState: '',
        returnLabelSendToEmail: '',
        returnDescription: '',
        returnReferenceNumber1: '',
        returnReferenceNumber2: '',
        hazmatTriggerWords: []
    },
    returnServiceSelected: false
}

export const initialShipmentModel = {
    serviceSelected: false, //make true when going rates to confirm and pass service code
    serviceCode: '',
    shipModel: {},
    rateModel: {
        requestedServiceTypes: [
            {
                carrier: 'UPS',
                serviceLevel: ''
            }
        ],
        shipDate: '',
        shipmentServiceOptions: {
            carbonNeutral: {
                value: ''
            },
            carbonNeutralSelected: false,
            returnService: {
                returnDeliveryMethod: "5",
                returnAddressAddress1: '',
                returnAddressAddress2: '',
                returnAddressCity: '',
                returnAddressCompany: '',
                returnAddressContact: '',
                returnAddressPhone: '',
                returnAddressPostalCode: '',
                returnAddressState: '',
                returnLabelSendToEmail: '',
                returnDescription: '',
                returnReferenceNumber1: '',
                returnReferenceNumber2: ''
            },
            returnServiceSelected: false
        },
        packages: [],
        pausedStep: 0,
        shipper: {
            fullName: '',
            firstName: '',
            lastName: '',
            contactName: '',
            companyName: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            phoneNumber: '',
            countryCode: '',
            phoneExt: '',
            phoneType: 0,
            email: '',
            isResidential: true,
            rewardsNumber: '',
            id: {
                guid: '',
                baseId: ''
            }
        },
        recipient: {
            fullName: '',
            firstName: '',
            lastName: '',
            contactName: '',
            companyName: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            phoneNumber: '',
            countryCode: '',
            phoneExt: '',
            phoneType: 0,
            email: '',
            isResidential: true,
            id: {
                guid: '',
                baseId: ''
            },
            distributionListName: ''
        },
        shipmentId: null,
        deliveryDateTime: '',
        // source: 1,
        saturdayRateSelected: false,
        commercialInvoice: {},
        isWeightUnitSwitched: false,
        documentsOnly: false
        // International
        // commercialInvoice: {
        //     additionalComments: "",
        //     reasonForExport: {
        //         id: {
        //             guid: "",
        //             baseId: ""
        //         },
        //         code: "",
        //         description: ""
        //     },
        //     invoiceNumber: "",
        //     purchaseOrderNumber: "",
        //     invoiceLineItems: [
        //         {
        //             contentDescription: "",
        //             unitsOfMeasure: {
        //                 id: {
        //                     guid: "",
        //                     baseId: ""
        //                 },
        //                 code: "",
        //                 description: ""
        //             },
        //             harmonizedTariffCode: "",
        //             countryOfOrigin: {
        //                 id: {
        //                     guid: "",
        //                     baseId: ""
        //                 },
        //                 code: "",
        //                 description: ""
        //             },
        //             countryOfOriginCode: "",
        //             productCode: "",
        //             quantity: 0,
        //             unitPrice: {
        //                 amount: 0,
        //                 currencyCode: "",
        //                 currencyName: "",
        //                 currencySymbol: "",
        //                 decimalDigits: 0,
        //                 isoCode: 0,
        //                 internalAmount: 0,
        //                 truncatedAmount: 0
        //             },
        //             totalItemValue: {
        //                 amount: 0,
        //                 currencyCode: "",
        //                 currencyName: "",
        //                 currencySymbol: "",
        //                 decimalDigits: 0,
        //                 isoCode: 0,
        //                 internalAmount: 0,
        //                 truncatedAmount: 0
        //             },
        //             itemNumber: 0,
        //             isItemValidated: true,
        //             termsOfSale: "",
        //             isDisableCustomsNextValueMax: true,
        //             isRestrictedFlag: true,
        //             isDisableCustomsNextHazmatError: true
        //         }
        //     ],
        //     termsOfSale: {
        //         id: {
        //             guid: "",
        //             baseId: ""
        //         },
        //         code: "",
        //         description: ""
        //     },
        //     totalShipmentValue: {
        //         amount: 0,
        //         currencyCode: "",
        //         currencyName: "",
        //         currencySymbol: "",
        //         decimalDigits: 0,
        //         isoCode: 0,
        //         internalAmount: 0,
        //         truncatedAmount: 0
        //     },
        //     invoiceDate: "",
        //     declarationStatement: "",
        //     brokerInformation: {
        //         brokerTaxId: "",
        //         brokerName: "",
        //         brokerCompany: "",
        //         brokerStreet1: "",
        //         brokerStreet2: "",
        //         brokerCity: "",
        //         brokerState: "",
        //         brokerPostalCode: "",
        //         brokerPhoneNumber: "",
        //         brokerCountry: "",
        //         brokerEmailAddress: ""
        //     },
        //     commercialInvoiceBillTo: {
        //         contactName: "",
        //         companyName: "",
        //         billToAddress1: "",
        //         billToAddress2: "",
        //         billToCity: "",
        //         billToState: "",
        //         billToPostalCode: "",
        //         billToCountry: "",
        //         billToPhoneNumber: ""
        //     }
        // },
        // distributionListName: ''
        // DCS
        // jobDetails: [
        //     {
        //         jobShipments: [
        //             {
        //                 quantity: 0,
        //                 completesShipment: true,
        //                 shipDate: "2021-12-14T19:40:14.162Z"
        //             }
        //         ],
        //         jobId: "",
        //         jobNo: "",
        //         shippingMethodName: "",
        //         jobStatus: "",
        //         dueDateTime: "",
        //         shipTo: {
        //             contactName: "",
        //             companyName: "",
        //             street1: "",
        //             street2: "",
        //             shipToAddress3: "",
        //             city: "",
        //             state: "",
        //             postalCode: "",
        //             country: "",
        //             countryCode: "",
        //             phoneNumber: "",
        //             phoneExt: "",
        //             phoneType: "",
        //             email: ""
        //         },
        //         shipFrom: {
        //             contactName: "",
        //             companyName: "",
        //             street1: "",
        //             street2: "",
        //             shipFromAddress3: "",
        //             city: "",
        //             state: "",
        //             postalCode: "",
        //             country: "",
        //             countryCode: "",
        //             phoneNumber: "",
        //             phoneExt: "",
        //             phoneType: "",
        //             email: ""
        //         },
        //         serviceLevels: "",
        //         carrier: "",
        //         productName: "",
        //         orderNo: "",
        //         jobShippingInfoId: "",
        //         totalQuantity: "",
        //         partialQuantity: "",
        //         remainingQuantity: "",
        //         jobCreatedDate: "",
        //         poNumber: "",
        //         firstName: "",
        //         lastName: "",
        //         companyName: "",
        //         itemName: "",
        //         itemDescription: "",
        //         deliveryMethod: ""
        //     }
        // ],
        // isRatesForNearestStore: true,
        // associateInitials: "",
        // isDcsShipment: true,
        // isBatchShipment: true,
        // batchShipmentNumber: "",
        // useThirdPartyAccount: true,
        // thirdPartyAccountDetails: {
        //     id: {
        //         guid: "",
        //         baseId: ""
        //     },
        //     contactName: "",
        //     companyName: "",
        //     carrierId: {
        //         guid: "",
        //         baseId: ""
        //     },
        //     accountNumber: "",
        //     billToAddress1: "",
        //     billToAddress2: "",
        //     billToCity: "",
        //     billToState: "",
        //     billToPhoneNumber: "",
        //     billToPostalCode: "",
        //     billToCountry: "",
        //     billToCountryCode: "",
        //     isResidential: true,
        //     isActive: true
        // },
        // isNonNationalCarrierShipment: true,
        // shipmentTotal: 0,
        // priceModificationApplied: true,
        // optInForTextNotifications: true,
        // mobileNumberForNotifications: "",
        // originalCarrierService: "",
        // upsellPriceChange: "",
        // isTextNotificationOn: true
    }
    // Gives errors
    // rateRequestId: {},
    // carrierName: "",
    // documentsOnly: false,
    // useThirdPartyAccount: false,
    // thirdPartyAccountDetails: {
    //     id: {
    //         guid: "",
    //         baseId: ""
    //     },
    //     contactName: "",
    //     companyName: "",
    //     carrierId: {
    //         guid: "",
    //         baseId: ""
    //     },
    //     accountNumber: "",
    //     billToAddress1: "",
    //     billToAddress2: "",
    //     billToCity: "",
    //     billToState: "",
    //     billToPhoneNumber: "",
    //     billToPostalCode: "",
    //     billToCountry: "",
    //     billToCountryCode: "",
    //     isResidential: true,
    //     isActive: true
    // }
}