export const pricingDefaultConfig = {
    pricingApi: `api/Admin/ItemSku`,
    preDefinedPackagesApi: `api/Admin/PreDefinedPackages`,
    carrierServiceLevelApi: `api/Admin/CarrierServiceLevels/Lookups`,
    additionalServicesApi: `api/JsTypes/Pni.Shipping.Interfaces.Enums.Configuration.AdditionalServices`,
    actions : {
        GetPricingData : 'GET_PRICING_DATA',
        AddPricingData : 'ADD_PRICING_DATA',
        UpdatePricingData : 'UPDATE_PRICING_DATA',
        DeletePricingData : 'DELETE_PRICING_DATA',
        ErrorPricingData : 'ERROR_PRICING_DATA',
        SuccessPricingData : 'SUCCESS_PRICING_DATA'
    }
}
