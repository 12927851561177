import {hubsConstants} from '../../constants/hubs-constants'

const initialState = {
    data: [],
    pagedData: {}
}

const OrdersJobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case hubsConstants.actions.GetOrdersJobsListData:
            return {
                ...state,
                data: action.data ? action.data.data : [],
                pagedData: action.data ? action.data.paging : {}
            }
            
        default:
            return state
    }
}

export default OrdersJobsReducer