import {facilitiesDefaultConfig} from "../../../constants/facilities-constants"
// ** Initial State
const initialState = {
    data: [],
    params: {},
    error: null,
    success: false
}

const {
    GetFacilitiesData,
    AddFacilitiesData,
    UpdateFacilitiesData,
    SuccessFacilitiesData,
    DeleteFacilitiesData,
    ErrorFacilitiesData
} = facilitiesDefaultConfig.actions

const facilitiesDataTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetFacilitiesData:
            return {
                ...state,
                data: action.data,
                params: action.params,
                error: null
            }
        case AddFacilitiesData:
            return { ...state, error: null }
        case UpdateFacilitiesData:
            return { ...state, error: null }
        case DeleteFacilitiesData:
            return { ...state }
        case ErrorFacilitiesData:
            return {
                ...state,
                error: action.error
            }
        case SuccessFacilitiesData:
            return {
                ...state,
                success: action.success
            }
        default:
            return state
    }
}

export default facilitiesDataTableReducer