import { isNullOrEmpty } from '../util/default-util'
import api from '../../api/api'

export default class CustomSettingsService {

  static customSettingsModel = {
    UnitOfCurrency: 'USD',
    MaxDeclaredValue: '5000',
    HardStop: false,
    ReturnService: false,
    ShipmentMaximumValue: {},
    DisableWeightInput: false,
    InternationalShippingAllowed: false,
    UpsApiIntegration: false,
    HarmonizedCode: false,
    HidePauseInternationalForms: false,
    BarcodeScanner: false,
    InternationalQuoteAllowed: false,
    MaximumPackagesValue: '',
    ThirdPartyBilling: false,
    RecipientPhoneNoRequired: false,
    POSSearchText: '',
    DropOffTrackingNumberLength: 0,
    FedExIsUsable: false,
    USPSIsUsable: false,
    DefaultRecipientPhone: '',
    HideShipmentSpinner: false,
    ReturnsIntegration: false,
    UspsTrackingNumberRegEx: '',
    UpsellInstruction: '',
    TurnOffShippingAgent: false,
    TurnOffManifestOptoroModal: false,
    TurnOffManifestHappyReturnsModal: false,
    HidePhoneNumberDropReturnFlow: false,
    EnableAmazonReturnsCoupons: false,
    DisplayFinishAndPrintOnAMD: false,
    DisplayDownloadAndPrintOnAMD: false,
    EnablePickups: false,
    AmazonReturnsDoubleScan: false
  }

  static culture = 'en-US'

  static systemSettings = []

  static async getSystemSetting(settingName, culture) {
    if (!isNullOrEmpty(culture)) {
      culture = 'en-US'
    }

    return await api.getAllData(`api/Settings/${settingName}/${culture}`)
      .then(function (data) {
        CustomSettingsService.systemSettings.push(data.data)
        return data
      })

  }

  static retrieveSettings() {

    this.getSystemSetting('DefaultCurrency', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.data.value) {
          CustomSettingsService.customSettingsModel.UnitOfCurrency = data.data.value
        }
      })

    this.getSystemSetting('Shipment.MaximumDeclaredValue', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.data.value) {
          CustomSettingsService.customSettingsModel.MaxDeclaredValue = data.data.value
        }
      })

    this.getSystemSetting('Shipment.HardStop', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.HardStop = data.value
        } else {
          CustomSettingsService.customSettingsModel.HardStop = false
        }
      })

    this.getSystemSetting('UPSReturnServices', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.ReturnService = data.value
        } else {
          CustomSettingsService.customSettingsModel.ReturnService = false
        }
      })

    this.getSystemSetting('UPSCanadaShipmentMaximumValue', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.ShipmentMaximumValue.CanadaValue = data.value
        }
      })

    this.getSystemSetting('UPSMexicoShipmentMaximumValue', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.ShipmentMaximumValue.MexicoValue = data.value
        }
      })

    this.getSystemSetting('UPSUSTerritoriesShipmentMaximumValue', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.ShipmentMaximumValue.USTerritoriesValue = data.value
        }
      })

    this.getSystemSetting('Shipment.DisableWeightInput', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.DisableWeightInput = data.value.toLocaleLowerCase() === 'true'
        }
      })

    this.getSystemSetting('Shipment.InternationalShippingAllowed', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.InternationalShippingAllowed = data.value.toLocaleLowerCase() === 'true'
        }
      })

    this.getSystemSetting('UpsApiIntegration', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.UpsApiIntegration = data.value
        } else {
          CustomSettingsService.customSettingsModel.UpsApiIntegration = false
        }
      })

    this.getSystemSetting('Shipment.HarmonizedCode', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.HarmonizedCode = data.value
        } else {
          CustomSettingsService.customSettingsModel.HarmonizedCode = false
        }
      })

    this.getSystemSetting('Shipment.HidePauseInternationalForms', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.HidePauseInternationalForms = data.value
        } else {
          CustomSettingsService.customSettingsModel.HidePauseInternationalForms = false
        }
      })

    this.getSystemSetting('Raven.BarcodeScanner', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.BarcodeScanner = data.value
        } else {
          CustomSettingsService.customSettingsModel.BarcodeScanner = false
        }
      })

    this.getSystemSetting('InternationalQuoteAllowed', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.InternationalQuoteAllowed = data.value
        } else {
          CustomSettingsService.customSettingsModel.InternationalQuoteAllowed = false
        }
      })

    this.getSystemSetting('Shipment.MaximumPackagesValue', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.MaximumPackagesValue = data.value
        }
      })

    this.getSystemSetting('DCSThirdPartyBilling', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.ThirdPartyBilling = data.value
        } else {
          CustomSettingsService.customSettingsModel.ThirdPartyBilling = false
        }
      })

    this.getSystemSetting('Shipment.RecipientPhoneNoRequired', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.RecipientPhoneNoRequired = data.value
        } else {
          CustomSettingsService.customSettingsModel.RecipientPhoneNoRequired = false
        }
      })

    this.getSystemSetting('DropOffTrackingNumberLength', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.DropOffTrackingNumberLength = data.value
        }
      })

    this.getSystemSetting('POSSearchText', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.POSSearchText = data.value
        }
      })

    this.getSystemSetting('FedExIsUsable', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          CustomSettingsService.customSettingsModel.FedExIsUsable = data.value
        } else {
          CustomSettingsService.customSettingsModel.FedExIsUsable = false
        }
      })

    this.getSystemSetting('USPSIsUsable', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          CustomSettingsService.customSettingsModel.USPSIsUsable = data.value
        } else {
          CustomSettingsService.customSettingsModel.USPSIsUsable = false
        }
      })

    this.getSystemSetting('DefaultRecipientPhone', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.DefaultRecipientPhone = data.value
        }
      })

    this.getSystemSetting('Shipment.HideSpinner', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          CustomSettingsService.customSettingsModel.HideShipmentSpinner = data.value
        } else {
          CustomSettingsService.customSettingsModel.HideShipmentSpinner = false
        }
      })

    //    ReturnsIntegration
    this.getSystemSetting('ReturnsIntegration', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          CustomSettingsService.customSettingsModel.ReturnsIntegration = data.value
        } else {
          CustomSettingsService.customSettingsModel.ReturnsIntegration = false
        }
      })

    this.getSystemSetting('UspsTrackingNumberRegEx', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.UspsTrackingNumberRegEx = data.value
        }
      })

    this.getSystemSetting('UpsellInstruction', CustomSettingsService.culture)
      .then(function (data) {
        if (data && data.value) {
          CustomSettingsService.customSettingsModel.UpsellInstruction = data.value
        }
      })

    this.getSystemSetting('TurnOffShippingAgent', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.TurnOffShippingAgent = data.value
        } else {
          CustomSettingsService.customSettingsModel.TurnOffShippingAgent = false
        }
      })

    this.getSystemSetting('TurnOffManifestOptoroModal', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.TurnOffManifestOptoroModal = data.value
        } else {
          CustomSettingsService.customSettingsModel.TurnOffManifestOptoroModal = false
        }
      })

    this.getSystemSetting('TurnOffManifestHappyReturnsModal', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.value) === 'true') {
          // This should be true and not "true"
          CustomSettingsService.customSettingsModel.TurnOffManifestHappyReturnsModal = data.value
        } else {
          CustomSettingsService.customSettingsModel.TurnOffManifestHappyReturnsModal = false
        }
      })

    this.getSystemSetting('HidePhoneNumberDropReturnFlow', CustomSettingsService.culture)
      .then(function (data) {
        // if ((data && data.value) === 'true') {
        //   // This should be true and not "true"
        //   CustomSettingsService.customSettingsModel.HidePhoneNumberDropReturnFlow = data.value
        // } else {
        //   CustomSettingsService.customSettingsModel.HidePhoneNumberDropReturnFlow = false
        // }
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.HidePhoneNumberDropReturnFlow = data.data.value
        }
      })

    this.getSystemSetting('EnableAmazonReturnsCoupons', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.EnableAmazonReturnsCoupons = data.data.value
        }

      })

    this.getSystemSetting('DisplayFinishAndPrintOnAMD', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.DisplayFinishAndPrintOnAMD = data.data.value
        }

      })

    this.getSystemSetting('DisplayDownloadAndPrintOnAMD', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.DisplayDownloadAndPrintOnAMD = data.data.value
        }

      })

    this.getSystemSetting('EnablePickups', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.EnablePickups = JSON.parse(data.data.value)
        }

      })

    this.getSystemSetting('AmazonReturnsDoubleScan', CustomSettingsService.culture)
      .then(function (data) {
        if ((data && data.data.value)) {
          CustomSettingsService.customSettingsModel.AmazonReturnsDoubleScan = JSON.parse(data.data.value)
        }

      })

  }

  static getMaximumDeclaredValue() {
    return CustomSettingsService.customSettingsModel.MaxDeclaredValue
  }

  static getDisplayCurrencyUnit() {
    return this.customSettingsModel.UnitOfCurrency
  }

  static getHazmatHardStop() {
    return this.customSettingsModel.HardStop
  }

  static getReturnServicePanel() {
    return this.customSettingsModel.ReturnService
  }

  static getMaximumShipmentInternationalValue() {
    return this.customSettingsModel.ShipmentMaximumValue
  }

  static getDisableWeightInputValue() {
    return this.customSettingsModel.DisableWeightInput
  }

  static isInternationalShippingAllowed() {
    return this.customSettingsModel.InternationalShippingAllowed
  }

  static getUpsApiIntegration() {
    return this.customSettingsModel.UpsApiIntegration
  }

  static getHarmonizedCode() {
    return this.customSettingsModel.HarmonizedCode
  }

  static getPauseInternationalForms() {
    return this.customSettingsModel.HidePauseInternationalForms
  }

  static getBarcodeScanner() {
    return this.customSettingsModel.BarcodeScanner
  }

  static getInternationalQuoteAccess() {
    return this.customSettingsModel.InternationalQuoteAllowed
  }

  static getMaximumPackagesValue() {
    return this.customSettingsModel.MaximumPackagesValue
  }

  static getThirdPartyBillingPanel() {
    return this.customSettingsModel.ThirdPartyBilling
  }

  static getRecipientPhoneNumber() {
    return this.customSettingsModel.RecipientPhoneNoRequired
  }

  static getDropOffTrackingNumberLength() {
    return this.customSettingsModel.DropOffTrackingNumberLength
  }

  static getPOSSearchText() {
    return this.customSettingsModel.POSSearchText
  }

  static getSettingsModel() {
    this.retrieveSettings()
  }

  static getFedExIsUsable() {
    return this.customSettingsModel.FedExIsUsable
  }

  static getUSPSIsUsable() {
    return this.customSettingsModel.USPSIsUsable
  }

  static getDefaultRecipientPhone() {
    return this.customSettingsModel.DefaultRecipientPhone
  }

  static getShipmentSpinner() {
    return this.customSettingsModel.HideShipmentSpinner
  }

  static getReturnsIntegration() {
    return this.customSettingsModel.ReturnsIntegration
  }

  static getUspsTrackingNumberRegEx() {
    return this.customSettingsModel.UspsTrackingNumberRegEx
  }

  static getUpsellInstruction() {
    return this.customSettingsModel.UpsellInstruction
  }

  static getTurnOffShippingAgentValue() {
    return this.customSettingsModel.TurnOffShippingAgent
  }

  static getTurnOffManifestOptoroModalValue() {
    return this.customSettingsModel.TurnOffManifestOptoroModal
  }

  static getTurnOffManifestHappyReturnsModalValue() {
    return this.customSettingsModel.TurnOffManifestHappyReturnsModal
  }

  static getPhoneNumberDropReturn() {
    return CustomSettingsService.customSettingsModel.HidePhoneNumberDropReturnFlow
  }

  static getEnableAmazonReturnsCoupons() {
    return CustomSettingsService.customSettingsModel.EnableAmazonReturnsCoupons
  }

  static getDisplayFinishAndPrintOnAMD() {
    return CustomSettingsService.customSettingsModel.DisplayFinishAndPrintOnAMD
  }

  static getDisplayDownloadAndPrintOnAMD() {
    return CustomSettingsService.customSettingsModel.DisplayDownloadAndPrintOnAMD
  }

  static getEnablePickups() {
    return CustomSettingsService.customSettingsModel.EnablePickups
  }

  static getAmazonReturnsDoubleScan() {
    return CustomSettingsService.customSettingsModel.AmazonReturnsDoubleScan
  }
}

