export const hubsConstants = {
  ordersJobsListApi: 'api/Orders/Filter',
  ordersJobsDetailsApi: 'api/Orders/JobDetails',
  dcsRatesApi: 'api/Rates/Dcs',
  dcsShipmentApi: 'api/Shipment/Dcs',
  actions: {
    GetOrdersJobsListData: 'GET_ORDERS_JOBS_LIST_DATA',
    GetOrdersJobsShippingDetails: 'GET_ORDERS_JOBS_SHIPPING_DETAILS',
    GetShipToAddressResult: 'GET_SHIP_TO_ADDRESS_RESULT',
    GetRatesTableResult: 'GET_RATES_TABLE_RESULT',
    GetRatesTableDisplay: 'GET_RATES_TABLE_DISPLAY',
    GetShipmentSuccess: 'GET_SHIPMENT_SUCCESS',
    GetDCSRatesCallForHubs: 'GET_rates',
    GetSelectedRate: 'GET_SELECTED_RATE'
  }
}
