// ** Initial State
import { fullServeDefaultConfig } from '../../constants/fullServe-constants'

const initialState = {
    next: false,
    showField: false,
    ratesData: [],
    data: {},
    initialFullServeModel: {},
    weightObj: {}
}

const {
    QuotesRates,
    QuotesError,
    QuotesForm,
    QuotesWeightScale
} = fullServeDefaultConfig.actions

const QuoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case QuotesRates:
            return {
                ...state,
                ratesData: action.data,
                next: action.next,
                initialFullServeModel: action.initialFullServeModel
            }
        case QuotesError:
            return {
                ...state,
                data: action.data,
                next: action.next,
                showField: action.showField
            }
        case QuotesForm:
            return {
                ...state,
                ratesModel: action.ratesModel,
                next: action.next
            }
        case QuotesWeightScale:
            return {
                ...state,
                weightObj: action.data
            }
        default:
            return state
    }
}

export default QuoteReducer