export const fullServeDefaultConfig = {
    customersSearchApi: 'api/Customers/Filter?',
    recipientSearchApi: 'api/Customers/',
    recipientSearchShipperApi: '/Addresses/Filter',
    carrierPackageDimensionsApi: 'api/Admin/PreDefinedPackages',
    carriersApi: 'api/Carriers/Lookups',
    carrierServiceLevelsApi: 'api/Admin/CarrierServiceLevels',
    ratesApi: 'api/Rates',
    shipItApi: 'api/Shipment',
    addressValidationApi: 'api/AddressValidation',
    expressAlertApi:'api/Returns/ShipmentAlert',
    happyAlertApi: 'api/Returns/happyReturns/ShipmentAlert',
    carriersNoNationalApi: 'api/Carriers',
    actions : {
        GetCustomersSearchData : 'GET_CUSTOMER_SEARCH_DATA',
        CustomerSuccessSearchData: 'CUSTOMER_SUCCESS_SEARCH_DATA',
        CustomerSavePayload: 'CUSTOMER_SAVE_PAYLOAD',
        GetAddressValidationResult: 'GET_ADDRESS_VALIDATION_RESULT',
        GetAddressFilledCorrect: 'GET_ADDRESS_FILLED_CORRECT',
        GetNextBtnDisable: 'GET_NEXT_BTN_DISABLE',
        GetRecipientSearchData: 'GET_RECIPIENT_SEARCH_DATA',
        RecipientSuccessSearchData: 'RECIPIENT_SUCCESS_SEARCH_DATA',
        RecipientSavePayload: 'RECIPIENT_SAVE_PAYLOAD',
        GetShipToAddressResult: 'GET_SHIP_TO_ADDRESS_RESULT',
        GetShipToAddressCorrect: 'GET_SHIP_TO_ADDRESS_CORRECT',
        GetShipToNextBtnDisable: 'GET_SHIP_TO_NEXT_BTN_DISABLE',
        PackageSavePayload: 'PACKAGE_SAVE_PAYLOAD',
        GetPackageNextBtnDisable: 'GET_PACKAGE_NEXT_BTN_DISABLE',
        RatesPayload: 'RATES_PAYLOAD',
        RatesResponse: 'RATES_RESPONSE',
        ResetPayload: 'RESET_PAYLOAD',
        RatesSelected: 'RATES_SELECTED',
        UpgradeService: 'UPGRADE_SERVICE',
        AddOnsRates: 'ADD_ONS_RATES',
        GetAddOnsNextBtnDisable: 'GET_ADD_ONS_NEXT_BTN_DISABLE',
        ReviewRates : 'REVIEW_RATES',
        ReviewPrevRates: 'REVIEW_PREV_RATES',
        FullServePayload: 'FULL_SERVE_PAYLOAD',
        SelectedRatesResponse: 'SELECTED_RATES_RESPONSE',
        ShipmentModel: 'SHIPMENT_MODEL',
        SuccessScreenData: 'SUCCESS_SCREEN_DATA',
        QuotesRates: 'QUOTES_RATES',
        QuotesError: 'QUOTES_ERROR',
        QuotesForm: 'QUOTES_FORM',
        ResetFullServeData: 'RESET_FULL_SERVE_DATA',
        PrevAddOnsRates: 'PREV_ADD_ONS_RATES',
        QuotesWeightScale: 'QUOTES_WEIGHT_SCALE',
        PackageWeightScale: 'PACKAGE_WEIGHT_SCALE',
        FailureFullServeData: 'FAILURE_FULL_SERVE_DATA'
    }
}