import {historyConstants} from '../../constants/history-constants'

const {
  GetShipmentsListData,
  GetShipmentDetail,
  GetDropOffListData,
  GetReturnListData,
  GetReturnDetail
} = historyConstants.actions

const initialState = {
  data: [],
  pagedData: {}
}

const HistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetShipmentsListData:
      return {
        ...state,
        data: action.data.data,
        pagedData: action.data.pagedData
      }
    
    case GetShipmentDetail:
      return {
        ...state,
        data: action.data
      }
    
    case GetDropOffListData:
      return {
        ...state,
        data: action.data.data,
        pagedData: action.data.pagedData
      }
    
    case GetReturnListData:
      return {
        ...state,
        data: action.data.data,
        pagedData: action.data.pagedData
      }
    
    case GetReturnDetail:
      return {
        ...state,
        data: action.data
      }
    
    default:
      return state
  }
}

export default HistoryReducer