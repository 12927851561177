import {settingsDefaultConfig} from '../../../constants/settings-constants'
// ** Initial State
const initialState = {
    data: [],
    params: {},
    error: null,
    success: false
}

const {
    GetSettingsData,
    UpdateSettingsData,
    AddSettingsData,
    SuccessSettingsData,
    DeleteSettingsData,
    ErrorSettingsData
} = settingsDefaultConfig.actions

const SettingsDataTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case GetSettingsData:
            return {
                ...state,
                data: action.data,
                params: action.params,
                error: null
            }
        case AddSettingsData:
            return { ...state, error: null }
        case UpdateSettingsData:
            return { ...state, error: null }
        case DeleteSettingsData:
            return { ...state }
        case ErrorSettingsData:
            return {
                ...state,
                error: action.error
            }
        case SuccessSettingsData:
            return {
                ...state,
                success: action.success
            }
        default:
            return state
    }
}

export default SettingsDataTableReducer